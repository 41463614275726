
import MainNavigator from './navigation/MainNavigator';

function App() {
  return (
    <MainNavigator />
  );
}

export default App;
