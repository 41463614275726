import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { lucario } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import rehypeRaw from "rehype-raw";

import Header from '../components/Header';
import Footer from '../components/Footer';

import CeoImage from '../assets/CEO_tenure.png';
import Nike10kImage from '../assets/Nike_Form_10k.png';
import NaImage from '../assets/NA_segment.png';

import "./RSEArticle.css";

const RSEArticle = () => {

    const tableData = {
        headers: ["", "Top-k", "RSE", "CCH + Top-k", "CCH + RSE"],
        rows: [
            ["AI Papers", "4.5", "7.9", "4.7", "7.9"],
            ["BVP Cloud", "2.6", "4.4", "6.3", "7.8"],
            ["Sourcegraph", "5.7", "6.6", "5.8", "9.4"],
            ["Supreme Court Opinions", "6.1", "8.0", "7.4", "8.5"],
            ["Average", "4.72", "6.73", "6.04", "8.42"]
        ]
    }

    return (
        <div className="article-section">
            <Header lightOrDark="dark" />

            <div className="article-container">
                <div className="article-title-container">
                    <p className="article-title-text">How to use dynamic retrieval granularity to improve RAG performance</p>
                    <p className="article-name-text">Zach McCormick</p>
                    <p className="article-date-text">September 23, 2024</p>
                </div>


                <div className="article-section-row">
                    <p className="article-header-text">Matching context length to query type</p>
                    <p className="article-paragraph">Many LLM applications require connecting the LLM to an external data source, such as company documents. We can think of the questions a user might ask over a document or set of documents as falling on a spectrum based on the length of the context string required to accurately answer the question. Let’s call this the optimal context length.</p>
                    <p className="article-paragraph">On one end of the spectrum we have factoid question answering. This is what most retrieval augmented generation (RAG) systems are built to handle. The implicit assumption is that there is a single sentence or paragraph somewhere in the documents that contains sufficient information to answer the user’s question. In other words, “the answer” is contained in a single chunk. The challenge then is just to find that chunk. Embeddings, hybrid search systems, rerankers, chunking methods, etc. are all built with this query type in mind.</p>
                    <p className="article-paragraph">On the other end of the spectrum we have summarization. Summarizing a document or set of documents requires giving the LLM access to the entire text of the document(s).</p>
                    <p className="article-paragraph">What this means is that optimal context length ranges from just a sentence or paragraph (~100 tokens) on one end of the spectrum, all the way to an entire document (10k-100k tokens) on the other end of the spectrum. This is a difference of 2-3 orders of magnitude. And there are a lot of queries where the optimal context length falls somewhere in between these two extremes. A query like “What are the main themes in Chapter 7?” will require a few thousand tokens of context. In most cases, a production-quality RAG system needs to be able to handle this full spectrum of query types.</p>
                    <p className="article-paragraph">The only way to provide the LLM with the exact context needed for a given query is to match the retrieval granularity to the query type. If you retrieve an entire document when all you need is one paragraph, the LLM may be able to get the right answer, but you’ll be wasting a huge amount of tokens. If you retrieve a bunch of paragraph-sized chunks when you need to retrieve an entire chapter, you’ll end up with a disjointed mess of context that will likely lead to an incomplete or misleading response from the LLM.</p>
                </div>

                <div className="article-section-row">
                    <p className="article-header-text">Spectrum of query types</p>
                    <p className="article-paragraph">We'll now describe five different query types we've identified, in order of increasing optimal context length.</p>
                    <p className="rse-article-bold-paragraph">1. Factoid Question Answering</p>
                    <p className="article-paragraph">Factoid questions seek specific, factual information that can typically be found in a single sentence or a brief passage within a document. The answers are concise and directly address the query. Examples of such questions include:</p>
                    <div className="rse-examples-container">
                        <p className="article-paragraph rse-example-text">- "Who are the authors of the paper?"</p>
                        <p className="article-paragraph rse-example-text">- "What is the capital city of France?"</p>
                        <p className="article-paragraph rse-example-text">- "When was the first iPhone released?"</p>
                    </div>
                    <p className="article-paragraph">For factoid questions, small chunks are the ideal retrieval unit. Since the required information is localized, retrieving a short passage or a single paragraph containing the answer is sufficient. This approach is efficient and effective because the answer exists within a confined segment of the text. The localization of information in factoid questions means that standard retrieval methods often suffice to retrieve the necessary data.</p>
                    <p className="rse-article-bold-paragraph">2. Descriptive Question Answering</p>
                    <p className="article-paragraph">Descriptive questions require detailed explanations that may span several sentences or paragraphs but remain focused on a specific aspect of the document. Examples include:</p>
                    <div className="rse-examples-container">
                        <p className="article-paragraph rse-example-text">- "What methodology did the researchers use in the study?"</p>
                        <p className="article-paragraph rse-example-text">- "Describe the key features of the proposed algorithm."</p>
                        <p className="article-paragraph rse-example-text">- "How does the immune system respond to vaccines?"</p>
                    </div>
                    <p className="article-paragraph">Descriptive questions may require anywhere from a few paragraphs to a few pages to encompass the full scope of information needed. While the information is still relatively localized to a section or subsection, it may not fit within a single small chunk.</p>
                    <p className="rse-article-bold-paragraph">3. Integrative Question Answering</p>
                    <p className="article-paragraph">Integrative questions require synthesizing information distributed across different parts of a document or even multiple documents. These questions often involve analysis, comparison, or drawing conclusions based on dispersed data. Examples are:</p>
                    <div className="rse-examples-container">
                        <p className="article-paragraph rse-example-text">- "What are the main conclusions of the paper?"</p>
                        <p className="article-paragraph rse-example-text">- "How do this study's findings compare with previous research?"</p>
                        <p className="article-paragraph rse-example-text">- "What implications do the results have for future technologies?"</p>
                    </div>
                    <p className="article-paragraph">For integrative questions, small chunks are insufficient because the necessary information is spread throughout the document. The retrieval system must provide larger sections or even the entire document to allow the LLM to access all relevant details. The dispersed nature of information in integrative questions means that key points are located in various parts of the text, and understanding relationships between different sections is critical.</p>
                    <p className="rse-article-bold-paragraph">4. Query-Focused Summarization</p>
                    <p className="article-paragraph">Query-Focused Summarization (QFS) aims to generate a summary that centers on specific aspects or questions posed by the user, as opposed to creating a general overview. Examples include:</p>
                    <div className="rse-examples-container">
                        <p className="article-paragraph rse-example-text">- "Summarize the experimental procedures and materials used in the study."</p>
                        <p className="article-paragraph rse-example-text">- "Provide an overview of the challenges discussed regarding climate change mitigation."</p>
                        <p className="article-paragraph rse-example-text">- "Summarize the author's arguments about artificial intelligence ethics."</p>
                    </div>
                    <p className="article-paragraph">QFS requires access to all portions of the document relevant to the query focus. Retrieving entire sections related to the query topic or aggregating multiple relevant sections provides the comprehensive input necessary for accurate summarization.</p>
                    <p className="rse-article-bold-paragraph">5. General Summarization</p>
                    <p className="article-paragraph">General summarization involves condensing an entire document into a concise summary without focusing on any specific aspect or query. The goal is to capture all key points, arguments, and conclusions presented.</p>
                    <p className="article-paragraph">For general summarization, the entire document is the necessary retrieval unit. The LLM needs access to all content to create an accurate and holistic summary. Using chunks in this context is inadequate, as it leads to incomplete summaries, potential misrepresentation, and fragmented narratives. Summarization requires comprehensive coverage, including all major points, and grasping the document's organization enhances summary quality. Partial retrieval could skew the summary towards certain sections, so providing the entire document is essential.</p>
                    <p className="article-paragraph">Note that in many cases general summarization isn’t a retrieval problem, as the document to be summarized is usually directly provided or selected by the user. But there are use cases where the user may want to reference the document to be summarized by name, and then the system needs to retrieve that document before it can summarize it.</p>

                </div>

                <div className="article-section-row">
                    <p className="article-header-text">Additional considerations</p>
                    <p className="rse-bold-header">What about queries that require multiple distinct pieces of context?</p>
                    <p className="article-paragraph">In our description of the five query types, we’ve glossed over the fact that there are queries that require multiple distinct pieces of context. For example, consider the query “Compare the revenue growth of Apple and Microsoft over the last three years.” That will require finding two distinct pieces of information from two different documents. Regardless of query type, it's pretty common to have queries that require a few (2-5) separate pieces of context to answer.</p>
                    <p className="rse-bold-header">What happens when your document corpus gets very large?</p>
                    <p className="article-paragraph">Some LLM applications require interacting with very large document corpora, potentially up to a few million documents. How does optimal context length scale with document corpus size for the different query types?</p>
                    <ul>
                        <li className="article-paragraph article-list-item">Factoid QA - O(1)</li>
                        <ul>
                            <li className="article-paragraph article-sub-list-item">If the answer is contained in a single chunk, then it doesn’t matter whether you have one document or one million documents, you still just need to provide the LLM with a single chunk (in practice, you’ll need to use at least a few, because search/ranking models aren’t perfect). The search problem can get challenging with large document corpora, but the optimal context length doesn’t change.</li>
                        </ul>
                        <li className="article-paragraph article-list-item">Descriptive QA - Pretty close to O(1)</li>
                        <ul>
                            <li className="article-paragraph article-sub-list-item">You might need to retrieve a page or two, but the information is still very localized.</li>
                        </ul>

                        <li className="article-paragraph article-list-item">Integrative QA - Most queries will be close to O(1)</li>
                        <ul>
                            <li className="article-paragraph article-sub-list-item">Integrative QA requires synthesizing information across a document or set of documents, so it’s possible that large document corpora will have larger amounts of information that need to be synthesized.</li>
                        </ul>
                        <li className="article-paragraph article-list-item">QFS - Some queries will be closer to O(1), while others will be closer to O(n)</li>
                        <ul>
                            <li className="article-paragraph article-sub-list-item">For QFS tasks, you generally need to retrieve all information about a certain topic. Depending on the nature of the document corpus and the query, a larger corpus could have more relevant information that needs to be retrieved, but that won’t always be the case. It really depends on the specificity of the query relative to the contents of the corpus.</li>
                        </ul>
                        <li className="article-paragraph article-list-item">Summarization - O(n)</li>
                        <ul>
                            <li className="article-paragraph article-sub-list-item">Since summarization requires seeing the entire document(s), this scales linearly with the corpus size.</li>
                        </ul>

                    </ul>

                    <p className="article-paragraph">Summarization is clearly the one with the worst scaling behavior. The good thing is that summarization is usually only needed for individual documents, rather than large document corpora. There aren’t many scenarios where you need to do pure summarization on a large corpus of documents (probably because doing so would compress the information so much that it wouldn’t be very useful). So this linear scaling is rarely an issue for pure summarization tasks.</p>
                    <p className="article-paragraph">But what about QFS? There are a lot of use cases where you would want to do QFS over a large corpus. For example, suppose you have a large database of customer service interactions and you want to analyze them. Imagine you want to find all instances of unhappy customers and identify the key things their complaints have in common. That would require first identifying all of the unhappy customer interactions, and then summarizing them with an eye towards identifying key themes. The number of unhappy interactions is going to scale linearly with the number of interactions. If you need to do this over millions of interactions, this becomes challenging.</p>

                </div>

                <div className="article-section-row">
                    <p className="article-header-text">Relevant segment extraction - an efficient way to achieve dynamic retrieval granularity</p>
                    <p className="article-paragraph">If you want to be able to support the full spectrum of query types, you need to be able to dynamically retrieve the correct context length for the query, whether that’s a single paragraph or an entire document. This isn’t how retrieval systems are usually built, so how do we do this?</p>
                    <p className="article-paragraph">Given that so much infrastructure has been built to do retrieval for individual chunks, we use the relevance of standard paragraph-sized chunks (~200 tokens) as a starting point for our solution. This lets us leverage existing embedding models and rerankers, which we wouldn’t want to have to rebuild ourselves. We rarely need to go more granular than a paragraph-sized chunk. Even in cases where all we really need is a single sentence, the additional cost of using a paragraph-sized chunk is very minimal, so we’ll assume the chunk is the smallest possible unit of retrieval.</p>
                    <p className="article-paragraph">Before we get into how relevant segment extraction (RSE) works, we need to introduce the concept of a chunk relevance plot. We’ll use Nike’s 2023 10-K to illustrate this. In the plots below, the x-axis represents the chunk index. The first chunk in the document has index 0, the next chunk has index 1, etc. There are 483 chunks in total for this document. The y-axis represents the relevance of each chunk to the query. We use the Cohere reranker to calculate the relevance scores.</p>
                    <p className="article-paragraph">Viewing it this way lets us see how relevant chunks tend to be clustered in one or more sections of a document. Queries towards the Factoid QA end of the spectrum will tend to have very small clusters, while queries towards the summarization end of the spectrum will have very large clusters.</p>
                    <p className="article-paragraph">Let's look at a few examples.</p>
                    <p className="article-paragraph">“Nike CEO tenure"</p>
                    <img src={CeoImage} alt="Nike CEO tenure" className="rse-article-image" />
                    <p className="article-paragraph">This is very much a factoid question. We can see there’s a cluster of seven contiguous chunks towards the beginning of the document, and a smaller cluster of three chunks at the very end.</p>
                    <p className="article-paragraph">“2023 Nike North America segment results”</p>
                    <img src={NaImage} alt="Nike North America segment results" className="rse-article-image" />
                    <p className="article-paragraph">This one has a few clusters, including one rather large one in the middle.</p>
                    <p className="article-paragraph">Now for a summarization task where we need to search for the entire document: “Nike Form 10-K”.</p>
                    <img src={Nike10kImage} alt="Nike 10-K" className="rse-article-image" />
                    <p className="article-paragraph">We can see that every chunk in the document is relevant to this query, because every chunk is part of the document being searched for. (Note that this one wouldn’t work very well if we weren’t using contextual chunk headers, as the phrase “Nike Form 10-K” isn’t contained in many of the raw chunks.)</p>
                    <p className="rse-bold-header">What can we do with these clusters of relevant chunks?</p>
                    <p className="article-paragraph">The main idea behind RSE is to identify clusters of relevant chunks and to return those clusters as contiguous segments of text, rather than just returning individual chunks. This lets us match the context length to the query type.</p>
                    <p className="article-paragraph">Now for the hard part: how do we actually identify these clusters algorithmically? A naive approach would be to just pick a relevance threshold and then combine adjacent chunks into segments if they’re all above the relevance threshold. The problem with this approach is that our relevance scores are pretty noisy. If you zoom in on the chunk relevance plots for a variety of queries, you’ll notice that it’s very common to have chunks with low relevance scores sandwiched between chunks with high relevance scores. In almost all of these cases, the “low relevance” chunks are actually quite relevant when you consider the surrounding context. So we need a way to identify segments that isn’t too sensitive to relevance scoring noise.</p>
                    <p className="article-paragraph">If we can calculate chunk values in such a way that the value of a segment is just the sum of the values of its constituent chunks, then finding the optimal segment is a version of the maximum sum subarray problem, for which a solution can be found relatively easily. How do we define chunk values in such a way? We'll start with the idea that highly relevant chunks are good, and irrelevant chunks are bad. We already have a good measure of chunk relevance (shown in the plots above), on a scale of 0-1, so all we need to do is subtract a constant threshold value from it. This will turn the chunk value of irrelevant chunks to a negative number, while keeping the values of relevant chunks positive.</p>
                    <p className="article-paragraph">We call this threshold value the <span className="code-text">irrelevant_chunk_penalty</span>. A value around 0.2 seems to work well empirically. Lower values will bias the results towards longer segments, and higher values will bias them towards shorter segments. This is because higher the <span className="code-text">irrelevant_chunk_penalty</span>, the harder it is for a segment to span across irrelevant chunks.
                    </p>
                    <p className="article-paragraph">Now we can solve the problem using a brute force search over all possible segments (with some heuristics to speed things up). There are more efficient algorithms for solving the maximum sum subarray problem, but none of them work with the constrained version of the problem we have here. Fortunately, the brute force method only takes ~5ms, even when using relatively unoptimized Python code, so this doesn’t add an appreciable amount of latency to the system. There are quite a few nuances to the implementation that we won’t get into here, but I encourage you to take a look at the <a href="https://github.com/D-Star-AI/dsRAG/blob/main/dsrag/rse.py" target="_blank">code</a> if you’re curious. It’s only a couple hundred lines, so it shouldn’t take too long to grasp what’s going on.</p>
                </div>

                <div className="article-section-row">
                    <p className="article-header-text">Evaluating performance</p>
                    <p className="article-paragraph">We've evaluated the performance of RSE on a variety of challenging RAG benchmarks. One important thing to note here is that we can’t use traditional information retrieval benchmarks, because they all contain an assumption of fixed retrieval granularity. We have to use end-to-end benchmarks, where we directly grade the accuracy of the LLM response, rather than trying to measure the precision and recall of the retrieved context. There aren’t many of these benchmarks available, and they’re difficult to create because they require writing challenging questions with ground truth answers. The best one we’ve found is FinanceBench. But we also wanted to evaluate performance on a wider range of domains, so we also created our own benchmark, called <a href="https://github.com/D-Star-AI/KITE" target="_blank">KITE</a> (Knowledge-Intensive Task Evaluation).</p>
                    <p className="rse-bold-header">KITE</p>
                    <p className="article-paragraph" style={{marginBottom: "10px"}}>KITE currently consists of 4 datasets and a total of 50 questions.</p>
                    <p className="article-paragraph rse-example-text">- <b>{"AI Papers "}</b>- ~100 academic papers about AI and RAG, downloaded from arXiv in PDF form.</p>
                    <p className="article-paragraph rse-example-text">- <b>{"BVP Cloud 10-Ks "}</b>- 10-Ks for all companies in the Bessemer Cloud Index (~70 of them), in PDF form.</p>
                    <p className="article-paragraph rse-example-text">- <b>{"Sourcegraph Company Handbook "}</b>- ~800 markdown files, with their original directory structure, downloaded from Sourcegraph's publicly accessible company handbook GitHub <a href="https://github.com/sourcegraph/handbook/tree/main/content" target="_blank">page</a>.</p>
                    <p className="article-paragraph rse-example-text">- <b>{"Supreme Court Opinions "}</b>- All Supreme Court opinions from Term Year 2022 (delivered from January '23 to June '23), downloaded from the official Supreme Court <a href="https://www.supremecourt.gov/opinions/slipopinion/22" target="_blank">website</a> in PDF form.</p>
                    <p className="article-paragraph" style={{marginTop: "30px"}}>Ground truth answers are included with each sample. Most samples also include grading rubrics. Grading is done on a scale of 0-10 for each question, with a strong LLM (Claude 3.5 Sonnet) doing the grading.</p>

                    <div className="table-container">
                        <div className="table-row">
                            {tableData.headers.map((header, index) => {
                                return <div key={index} className={`table-cell table-cell-${index}`}>
                                    <p className="table-header-text">{header}</p>
                                </div>
                            })}
                        </div>
                        {tableData.rows.map((row, index) => {
                            return (
                                <div key={index} className={`table-row table-row-${index}`}>
                                    {row.map((cell, colIndex) => {
                                        return <div key={colIndex} className={`table-cell table-cell-${colIndex}`}>
                                            <p className={`table-text table-text-${index}-${colIndex}`}>{cell}</p>
                                        </div>
                                    })}
                                </div>
                            )
                        })}
                    </div>

                    <p className="article-paragraph">We can see that RSE leads to an improvement in performance on each of the four datasets. The overall average score increases from 4.72 {"->"} 6.73, a 42.6% increase. Adding <a href="https://github.com/NirDiamant/RAG_Techniques/blob/main/all_rag_techniques/contextual_chunk_headers.ipynb" target="_blank">contextual chunk headers</a> (CCH) bumps this up to 8.42. We’ve found that using RSE in combination with CCH works very well.</p>
                    <p className="rse-bold-header">FinanceBench</p>
                    <p className="article-paragraph">FinanceBench uses a corpus of a few hundred 10-Ks and 10-Qs. The queries are challenging, and often require combining multiple pieces of information. Ground truth answers are provided. Answers are graded manually on a pass/fail basis. Minor allowances for rounding errors are allowed, but other than that the answer must exactly match the ground truth answer to be considered correct.</p>
                    <p className="article-paragraph">We were able to achieve a score of 83% on FinanceBench, compared to a baseline of 19%. For this benchmark, we tested CCH and RSE jointly, so we can't say exactly how much RSE contributed to that result. But the combination of CCH and RSE clearly leads to substantial accuracy improvements on FinanceBench.</p>

                </div>

                <div className="article-section-row">
                    <p className="article-header-text">Try it for yourself</p>
                    <p className="article-paragraph">If you want to give RSE a try, we’ve open-sourced a retrieval engine that implements it, called <a href="https://github.com/D-Star-AI/dsRAG" target="_blank">dsRAG</a>. You can also play around with the <a href="https://github.com/D-Star-AI/dsRAG/blob/main/examples/dsRAG_motivation.ipynb" target="_blank">iPython notebook</a> we used to run these examples and generate the plots. And if you want to use this with LangChain, we have a <a href="https://github.com/D-Star-AI/dsRAG/blob/main/integrations/langchain_retriever.py" target="_blank">LangChain custom retriever</a> implementation as well.</p>
                </div>

            </div>


            <Footer />
        </div>
    )

}


const Markdown = ({ markdownContent }) => {

    return (
        <ReactMarkdown
            children={markdownContent}
            rehypePlugins={[rehypeRaw]}
            components={{
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                        <div>
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                language={match[1]}
                                style={lucario}
                                customStyle={{ fontSize: "14px" }}
                                PreTag="div"
                                {...props}
                            />
                        </div>
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    )
                },
                iframe: ({ node, ...props }) => (
                    <div className="blog-post-video-container">
                        <iframe {...props} className="blog-post-video" />
                    </div>
                ),
                a: ({ href, children }) => (
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        {children}
                    </a>
                ),
                b: ({ children }) => (
                    <b>{children}</b>
                ),
                smallp: ({ children }) => (
                    <p style={{fontSize: "12px", lineHeight: "18px"}}>{children}</p>
                ),
                container: ({ children }) => (
                    <div style={{marginTop: "30px"}}>{children}</div>
                ),
            }}
        />
    )

}


export default RSEArticle;