import React from "react";
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import Footer from '../components/Footer';

import "./Blogs.css";

const Blogs = () => {

    const navigate = useNavigate();

    return (
        <div className="blogs-section">

            <Header lightOrDark="dark" />

            <div className="blogs-main-container">

                <div className="blogs-container">
                    <div className="blogs-list">

                        <div className="blog-card" onClick={() => navigate("/dynamic-retrieval-granularity")}>
                            <p className="blog-title">How to use dynamic retrieval granularity to improve RAG performance</p>
                            <p className="blog-subtitle">We can think of the questions a user might ask over a document or set of documents as falling on a spectrum based on the length of the context string required to accurately answer the question. Let’s call this the optimal context length. On one end of the spectrum we have factoid question answering.</p>
                            <div className="blog-name-date-row">
                                <p className="blog-name">Zach McCormick</p>
                                <p className="blog-date">September 23, 2024</p>
                            </div>
                        </div>

                        <div className="blog-card" onClick={() => navigate("/solving-the-out-of-context-chunk-problem-for-rag")}>
                            <p className="blog-title">Solving the out-of-context chunk problem for RAG</p>
                            <p className="blog-subtitle">Many of the problems developers face with RAG come down to this: Individual chunks don’t contain sufficient context to be properly used by the retrieval system or the LLM. This leads to the inability to answer seemingly simple questions and, more worryingly, hallucinations.</p>
                            <div className="blog-name-date-row">
                                <p className="blog-name">Zach McCormick</p>
                                <p className="blog-date">July 17, 2024</p>
                            </div>
                        </div>

                        <div className="blog-card" onClick={() => navigate("/embeddings-are-not-all-you-need")}>
                            <p className="blog-title">Embeddings are not all you need</p>
                            <p className="blog-subtitle">LLMs are severely limited by their lack of access to external data. Most valuable use cases for LLMs require connecting external data in some way. What’s the use of an enterprise AI assistant if it doesn’t know anything about your company?</p>
                            <div className="blog-name-date-row">
                                <p className="blog-name">Zach McCormick</p>
                                <p className="blog-date">July 3, 2024</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <Footer />

        </div>
    )

}

export default Blogs;